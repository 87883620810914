import { CSSProperties, useEffect, useRef } from 'react';

// ----------------------------------------------------------------------

type TextWithRedirectLinkProps = {
  text: string;
  fontSize?: 'xl' | 'lg' | 'md' | 'sm';
  linkHref: string[];
  linkStyle?: CSSProperties;
};

// ----------------------------------------------------------------------

export default function TextWithRedirectLink({
  text,
  fontSize = 'sm',
  linkHref,
  linkStyle = {},
}: TextWithRedirectLinkProps) {
  const textRef = useRef<HTMLParagraphElement>(null);

  // HELPER FUNCTION
  function applylinkStyle(element: HTMLElement, linkStyle: CSSProperties, linkHref: string[]) {
    const linkElements = element.getElementsByTagName('a');

    for (let i = 0; i < linkElements.length; i++) {
      const linkElement = linkElements[i];
      linkElement.href = linkHref[i];
      linkElement.target = '_blank';
      Object.assign(linkElement.style, linkStyle);
    }
  }
  //
  useEffect(() => {
    if (textRef.current) {
      textRef.current.innerHTML = text;
      applylinkStyle(textRef.current, linkStyle, linkHref);
    }
  }, [linkStyle, text, linkHref]);

  return (
    <p ref={textRef} className={`gv-text-${fontSize}`}>
      {text}
    </p>
  );
}
