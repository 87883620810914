// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  iconName: string;
  link: string;
  title: string;
  description: string;
};

// ----------------------------------------------------------------------

export default function HelpCard({ title, description, iconName, link }: Props) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div
      className="gv-content-container gv-p-lg gv-flex-row-md gv-items-center gv-flex-1"
      onClick={() => window.open(link, '_blank')}
      style={{
        minWidth: '0',
        cursor: 'pointer',
      }}
    >
      <div style={{ minWidth: '24px' }}>
        <gv-icon src={`/src/icons/${iconName}.svg`} />
      </div>
      <div className="gv-flex-1 gv-text-truncate">
        <p className="gv-text-lg gv-text-bold gv-text-truncate">{translate(title)}</p>
        <p className="gv-caption-lg gv-text-on-alternative gv-text-truncate">
          {translate(description)}
        </p>
      </div>
      <div style={{ minWidth: '24px' }}>
        <gv-icon src="/src/icons/arrow_forward.svg" />
      </div>
    </div>
  );
}
