import { useParams } from 'react-router';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useLocales from 'src/hooks/useLocales';
// components
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  action?: {
    text: string;
    startIcon?: string;
    endIcon?: string;
    onClick: VoidFunction;
    disabled?: boolean;
  };
  breadcrumbs?: boolean;
};

// ----------------------------------------------------------------------

export default function Header({ title, action, breadcrumbs = true }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <div className="gv-flex-column-sm">
      {breadcrumbs && (
        <ul className="gv-breadcrumbs">
          <li>
            <a href="/sites">{translate('dashboard.sites.title')}</a>
            <gv-icon src="/src/icons/chevron_right.svg" />
          </li>
          <li>
            <span>{translate('dashboard.sites.details.mwp.sidebarTitle')}</span>
          </li>
        </ul>
      )}

      <div className="gv-flex-column-sm gv-tab-flex-row gv-tab-justify-between">
        <div className="gv-flex gv-flex-col">
          <p className="gv-heading-lg">{translate(title)}</p>
          <p className="gv-heading-sm gv-text-on-alternative">{name}</p>
        </div>

        {action && (
          <Button
            condensed={isMobile}
            text={translate('dashboard.sites.details.mwp.overview.action')}
            startIcon={action.startIcon}
            endIcon={action.endIcon}
            onClick={action.onClick}
            disabled={action.disabled || false}
          />
        )}
      </div>
    </div>
  );
}
