import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';
// @mui
import { Box, Container, ContainerProps } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useLocales from 'src/hooks/useLocales';
//
import PageLayout from './PageLayout';

// ----------------------------------------------------------------------

interface Props extends ContainerProps {
  children: ReactNode;
  title: string;
  noPaddingY?: boolean; // CheckEmail - 403 - 404
  enableBackgroundColor?: boolean;
  meta?: ReactNode;
}

// ----------------------------------------------------------------------

const SidebarPage = forwardRef<HTMLDivElement, Props>(
  ({ children, title = '', noPaddingY, enableBackgroundColor, meta, ...other }, ref) => {
    const { tabname, cluster, namespace, name } = useParams();

    // HOOK
    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'desktop_min');

    // STATE
    const { headerHeight, footerHeight, overflowPadding, tabConfig } = useSelector(
      (state) => state.layout
    );

    return (
      <>
        <Helmet>
          <title>{`${title} | WP.one`}</title>
          {meta}
        </Helmet>

        {isDesktop && tabConfig ? (
          <Box
            sx={{
              mr: `${overflowPadding}px`,
              backgroundColor: enableBackgroundColor ? '#f7f7f7' : '#ffffff',
            }}
          >
            <Container
              sx={{
                maxWidth: '1536px !important',
                minHeight: `calc(100vh - ${headerHeight + footerHeight}px)`,
                p: '0 !important',
                display: 'flex',
              }}
            >
              <div className="gv-activated">
                <div className="gv-sidebar gv-py-fluid" style={{ height: '100%' }}>
                  <div className="gv-side-menu">
                    <div className="gv-side-label">{translate(tabConfig.title)}</div>
                    <nav className="gv-side-nav">
                      <ul>
                        {tabConfig.navConfig.map((tab, index) => (
                          <RouterLink
                            key={index}
                            to={
                              tabConfig.primaryPathname === 'sites'
                                ? `/${tabConfig.primaryPathname}/${tab.tabName}/${cluster}/${namespace}/${name}`
                                : tabConfig.primaryPathname === 'dns'
                                ? `/${tabConfig.primaryPathname}/${tab.tabName}/${name}`
                                : `/${tabConfig.primaryPathname}/${tab.tabName}`
                            }
                            className={`gv-nav-item${tab.tabName === tabname ? ' gv-active' : ''}`}
                          >
                            <gv-icon src={`/src/icons/${tab.icon}.svg`} />
                            <span className="gv-text-truncate">{translate(tab.title)}</span>
                          </RouterLink>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>

              <Container
                ref={ref}
                maxWidth={false}
                {...other}
                sx={{
                  py: isDesktop ? 6 : 3,
                  px: `${isDesktop ? 48 : 24}px !important`,
                  ...other.sx,
                }}
              >
                {children}
              </Container>
            </Container>
          </Box>
        ) : (
          <PageLayout
            ref={ref}
            noPaddingY={noPaddingY}
            enableBackgroundColor={enableBackgroundColor}
          >
            {children}
          </PageLayout>
        )}
      </>
    );
  }
);

export default SidebarPage;
