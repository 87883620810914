import { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { displayToast } from 'src/utils/handleToast';
//
import { TextField, Textarea } from './form';

// ----------------------------------------------------------------------

type Props = {
  value: string;
  label?: string;
  labelId?: string; // Required for label
  labelOptional?: string;
  labelIcon?: string;
  labelIconTooltip?: string;
  readOnly?: boolean; // Only for visual, no extra functionality
  textarea?: boolean;
};

// ----------------------------------------------------------------------

export default function CopyClipboard({
  value,
  label,
  labelId,
  labelOptional,
  labelIcon,
  labelIconTooltip,
  readOnly,
  textarea,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const [state, setState] = useState({
    value,
    copied: false,
  });

  // EVENT FUNCTION
  const onCopy = () => {
    setState({ ...state, copied: true });
    if (state.value) {
      displayToast(translate('wpone.general.action.copied'));
    }
  };

  //
  useEffect(() => {
    setState((prevState) => ({ ...prevState, value: value }));
  }, [value]);

  return !textarea ? (
    <TextField
      defaultValue={state.value}
      label={label}
      labelId={labelId}
      labelOptional={labelOptional}
      labelIcon={labelIcon}
      labelIconTooltip={labelIconTooltip}
      nonInputElement
      addonSuffix={
        <CopyToClipboard text={state.value} onCopy={onCopy}>
          <button type="button" className="gv-addon">
            <gv-icon src="/src/icons/content_copy.svg" />
          </button>
        </CopyToClipboard>
      }
      readOnly={readOnly}
    />
  ) : (
    <Textarea
      defaultValue={state.value}
      label={label}
      labelId={labelId}
      labelOptional={labelOptional}
      labelIcon={labelIcon}
      labelIconTooltip={labelIconTooltip}
      addon={
        <CopyToClipboard text={state.value} onCopy={onCopy}>
          <button type="button" className="gv-addon">
            <gv-icon src="/src/icons/content_copy.svg" />
          </button>
        </CopyToClipboard>
      }
      readOnly={readOnly}
    />
  );
}
