import { ChangeEvent, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { dispatch, useSelector } from 'src/redux/store';
import { resourceApi } from 'src/redux/api/resourceApi';
import { userApi } from 'src/redux/api/userApi';
import { siteApi, useDeleteSiteMutation } from 'src/redux/api/siteApi';
// form
import { useForm } from 'react-hook-form';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { UserRoleEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { TextField, Checkbox } from 'src/components/gravity/form';
import { RHFCheckbox, FormProvider } from 'src/components/gravity/hook-form';
import { TextWithBoldDynamicContent } from 'src/components/gravity/text';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type FormValuesProps = {
  immediate: boolean;
};

type Props = {
  site: ListSiteResponseDTO | null;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteSiteForm({ site, onClose }: Props) {
  const { name } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  // API
  const [deleteSite, { isLoading }] = useDeleteSiteMutation();

  // STATE
  const { user } = useSelector((state) => state.auth);

  const [check, setCheck] = useState(false);

  const [confirmText, setConfirmText] = useState('');

  // FORM
  const defaultValues = {
    immediate: site?.state === 'suspended' ? true : false,
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const { handleSubmit } = methods;

  // EVENT FUNCTION
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmText(event.target.value);
  };

  const onSubmit = async (data: FormValuesProps) => {
    if (!site) return;
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'delete-site',
    });

    deleteSite({
      cluster: site.cluster.name,
      namespace: site.namespace,
      immediate: data.immediate,
    })
      .unwrap()
      .then(() => {
        displayToast(
          translate(
            'wpone.sites.details.settings.delete.deleteSiteDialog.requestResponse.successMessage'
          )
        );

        // For getting latest update on Customer table (for support/service)
        dispatch(userApi.util.invalidateTags([{ type: 'User', id: 'PARTIAL-LIST' }]));

        if (user?.role === UserRoleEnum.customer || data.immediate) {
          navigate('/sites');
        } else {
          // For getting latest update on Site Details if user is not a customer
          dispatch(siteApi.util.invalidateTags([{ type: 'Sites', id: 'PARTIAL-LIST' }]));

          // For getting latest update on Site User permissions
          dispatch(resourceApi.util.invalidateTags([{ type: 'Resources', id: 'PARTIAL-LIST' }]));
        }

        onClose();
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        onClose();
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.settings.delete.deleteSiteDialog.title')}
        </h2>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-lg" style={{ overflowX: 'auto' }}>
            <p>
              {translate('wpone.sites.details.settings.delete.deleteSiteDialog.description', {
                siteName: name,
              })}
            </p>

            {user?.role !== UserRoleEnum.customer && site?.state !== 'suspended' && (
              <RHFCheckbox
                name="immediate"
                label={translate(
                  'wponesupport.sites.details.settings.delete.deleteSiteDialog.immediate'
                )}
                labelId="immediate-site-deletion"
                condensed
              />
            )}

            <p className="gv-text-lg">
              {translate('wpone.sites.details.settings.delete.deleteSiteDialog.confirmation1')}
            </p>

            <Checkbox
              checked={check}
              onChange={() => setCheck((prev) => !prev)}
              label={translate(
                'wpone.sites.details.settings.delete.deleteSiteDialog.confirmation2',
                {
                  siteName: name,
                }
              )}
              labelId="delete-site-confirmation"
              condensed
            />

            <TextWithBoldDynamicContent
              text={translate(
                'wpone.sites.details.settings.delete.deleteSiteDialog.confirmation3',
                {
                  siteName: name,
                }
              )}
              fontSize="lg"
            />

            <TextField
              label={translate('wpone.general.inputField.siteDomain.label')}
              labelId="site-name-delete"
              placeholder={name}
              value={confirmText}
              onChange={handleChange}
            />
          </div>
        </FormProvider>
      </div>
      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          uiType="destructive"
          text={translate('wpone.sites.details.settings.delete.deleteSiteDialog.action')}
          onClick={handleSubmit(onSubmit)}
          disabled={confirmText !== `${name}` || !check || isLoading}
        />
      </ButtonGroup>
    </>
  );
}
