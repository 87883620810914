// @mui
import { styled } from '@mui/material/styles';
import { Card, Table, TableBody, TableContainer } from '@mui/material';
// @types
import { KeyUpdatedDetailsExample, UpdatedDetails } from '@joonasvanhatapio/wp-cloud-backend-types';
// components
import Scrollbar from 'src/components/Scrollbar';
import { TableHeadCustom } from 'src/components/table';
//
import UpdateDetailsTableRow from './UpdateDetailsTableRow';

// ----------------------------------------------------------------------

const SubtableStyle = styled(Card)(({ theme }) => ({
  maxWidth: 'calc(100% + 80px)',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '-40px !important',
    marginRight: '-40px !important',
    marginBottom: '-32px !important',
    borderRadius: 0,
  },
}));

// ----------------------------------------------------------------------

// For table column width management:
// - maxWidth in TableCell and minWidth in main table for preventing shifting header
// - width in main table for preventing auto expand when there are extra space (due to few cols selected...). But one must not be set, else all columns will be auto expanded
const TABLE_HEAD = [
  {
    id: 'status',
    label: 'wpone.sites.details.updates.report.table.header.status',
    minWidth: 250,
    width: 250,
  },
  {
    id: 'version',
    label: 'wpone.sites.details.updates.report.table.header.version',
    minWidth: 250,
    width: 250,
  },
  {
    id: 'action',
    label: '',
    align: 'right',
    minWidth: 250,
    width: 250,
  },
];

// ----------------------------------------------------------------------

type Props = {
  name: 'wp' | 'plugin' | 'theme';
  data: KeyUpdatedDetailsExample | UpdatedDetails;
  onOpen: VoidFunction;
};

// ----------------------------------------------------------------------

export default function UpdateDetailsTable({ name, data, onOpen }: Props) {
  const tableData = data;

  const tableHeadConvert = (header: string) => {
    if (header === 'wp') {
      return 'Wordpress Core';
    } else if (header === 'plugin') {
      return 'Plugin';
    } else if (header === 'theme') {
      return 'Theme';
    } else {
      return '';
    }
  };

  const header = tableHeadConvert(name);

  const updatedTableHead = [
    {
      id: `${header}`,
      label: `wpone.sites.details.updates.report.table.header.${name}`,
      minWidth: 250,
    },
    ...TABLE_HEAD,
  ];

  return (
    <SubtableStyle>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table>
            <TableHeadCustom headLabel={updatedTableHead} />

            <TableBody>
              {name === 'wp' ? (
                <UpdateDetailsTableRow
                  row={tableData as UpdatedDetails}
                  onOpen={onOpen}
                  header={header}
                />
              ) : (
                Object.values(tableData).map((row, index) => (
                  <UpdateDetailsTableRow key={index} row={row} onOpen={onOpen} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </SubtableStyle>
  );
}
