import { ReactNode } from 'react';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fCapitalizeFirstLetter } from 'src/utils/format';
// components
import Indicator from 'src/components/gravity/Indicator';
import Resource from 'src/components/gravity/Resource';
import { TextCustomize } from 'src/components/gravity/text';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  description: string;
  status?: {
    uptime: 'alert' | 'info' | 'success' | 'warning'; // down - unknown - up - warning
    state: string; // migrating - restoring - active
  };
  resources?: {
    usage: number;
    limit: number;
    action?: ReactNode;
  };
};

// ----------------------------------------------------------------------

export default function StatusResourcesCard({ title, description, status, resources }: Props) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div className="gv-card gv-flex-1" style={{ backgroundColor: 'var(--color-surface-bright)' }}>
      <div className="gv-card-content">
        <h3 className="gv-card-title">{translate(title)}</h3>

        {status && (
          <Indicator type={status.uptime} text={fCapitalizeFirstLetter(translate(status.state))} />
        )}

        {resources && (
          <Resource
            amount={translate('wpone.sites.details.overview.statusResources.resourcesDescription', {
              used: resources.usage,
              limit: resources.limit === Number.MAX_VALUE ? '∞' : resources.limit,
            })}
            currentVal={resources.usage}
            maxVal={resources.limit}
            defaultThresholds
            style={{
              marginTop: '16px',
            }}
          />
        )}

        <TextCustomize
          extraClass="gv-text-on-alternative"
          text={translate(description, {
            limit: resources?.limit, // Only for Disk usage card
          })}
        />
      </div>

      {resources?.action && <div className="gv-card-footer">{resources.action}</div>}
    </div>
  );
}
