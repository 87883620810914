import { forwardRef, ReactNode } from 'react';
// redux
import { useSelector } from 'src/redux/store';
// @mui
import { Box, Container, ContainerProps } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

interface Props extends ContainerProps {
  children: ReactNode;
  noPaddingY?: boolean; // CheckEmail - 403 - 404
  enableBackgroundColor?: boolean;
}

// ----------------------------------------------------------------------

const PageLayout = forwardRef<HTMLDivElement, Props>(
  ({ children, noPaddingY, enableBackgroundColor, ...other }, ref) => {
    // HOOK
    const isDesktop = useResponsive('up', 'desktop_min');

    // STATE
    const { headerHeight, footerHeight, overflowPadding } = useSelector((state) => state.layout);

    return (
      <Box
        sx={{
          mr: `${overflowPadding}px`,
          backgroundColor: enableBackgroundColor ? '#f7f7f7' : '#ffffff',
        }}
      >
        <Container
          ref={ref}
          {...other}
          sx={{
            maxWidth: '1536px !important',
            minHeight: `calc(100vh - ${headerHeight + footerHeight}px)`,
            py: noPaddingY ? 0 : isDesktop ? 6 : 3,
            px: `${isDesktop ? 48 : 24}px !important`,
            ...other.sx,
          }}
        >
          {children}
        </Container>
      </Box>
    );
  }
);

export default PageLayout;
