import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
// @mui
import { Container, ContainerProps } from '@mui/material';
//
import PageLayout from './PageLayout';

// ----------------------------------------------------------------------

interface Props extends ContainerProps {
  children: ReactNode;
  title: string;
  noPaddingY?: boolean; // CheckEmail - 403 - 404
  fullWidth?: boolean; // Login
  enableBackgroundColor?: boolean;
  meta?: ReactNode;
}

// ----------------------------------------------------------------------

const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = '', noPaddingY, fullWidth, enableBackgroundColor, meta, ...other }, ref) => (
    <>
      <Helmet>
        <title>{`${title} | WP.one`}</title>
        {meta}
      </Helmet>

      {fullWidth ? (
        <Container
          maxWidth={false}
          ref={ref}
          {...other}
          sx={{
            px: `0 !important`,
            ...other.sx,
          }}
        >
          {children}
        </Container>
      ) : (
        <PageLayout ref={ref} noPaddingY={noPaddingY} enableBackgroundColor={enableBackgroundColor}>
          {children}
        </PageLayout>
      )}
    </>
  )
);

export default Page;
