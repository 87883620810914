// @mui
import { TableRow, TableCell, Skeleton, TableRowProps } from '@mui/material';

// ----------------------------------------------------------------------

type SkeletonProps = {
  checkbox: boolean;
  headers: any[];
};

export default function TableSkeleton({
  checkbox,
  headers,
  ...other
}: SkeletonProps & TableRowProps) {
  return (
    <TableRow {...other}>
      {checkbox && (
        <TableCell padding="checkbox">
          <Skeleton
            variant="text"
            width="100%"
            height={38}
            animation="wave"
            sx={{
              px: (theme) => theme.spacing(3),
            }}
          />
        </TableCell>
      )}
      {headers.map((header, index) => (
        <TableCell key={index} align={header.align || 'left'}>
          <Skeleton variant="text" height={38} animation="wave" />
        </TableCell>
      ))}
    </TableRow>
  );
}
