import ReactGA from 'react-ga4';
// redux
import { dispatch } from 'src/redux/store';
import { resourceApi } from 'src/redux/api/resourceApi';
import { userApi } from 'src/redux/api/userApi';
import { useActivateSiteMutation } from 'src/redux/api/siteApi';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO | null;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function ReactivateSiteConfirmation({ site, onClose }: Props) {
  // HOOK
  const { translate } = useLocales();

  // API
  const [activateSite, { isLoading }] = useActivateSiteMutation();

  // EVENT FUNCTION
  const handleReactivateSite = () => {
    if (!site) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'activate-site',
    });

    activateSite({ cluster: site.cluster.name, namespace: site.namespace })
      .unwrap()
      .then(() => {
        displayToast(
          translate(
            'wpone.sites.details.settings.reactivate.reactivateSiteDialog.requestResponse.successMessage'
          )
        );

        // For getting latest update on Site User permissions
        dispatch(resourceApi.util.invalidateTags([{ type: 'Resources', id: 'PARTIAL-LIST' }]));

        // For getting latest update on Customer table
        dispatch(userApi.util.invalidateTags([{ type: 'User', id: 'PARTIAL-LIST' }]));

        onClose();
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        onClose();
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.settings.reactivate.reactivateSiteDialog.title')}
        </h2>
        <p style={{ overflowX: 'auto' }}>
          {translate('wpone.sites.details.settings.reactivate.reactivateSiteDialog.description')}
        </p>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.sites.details.settings.reactivate.reactivateSiteDialog.action')}
          onClick={handleReactivateSite}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
