// @mui
import { Box, Typography } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  status: 'live' | 'staging';
};

// ----------------------------------------------------------------------

export default function StatusBlock({ status }: Props) {
  // HOOK
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        py: 0.5,
        px: 1.5,
        textAlign: 'center',
        borderRadius: 0.5,
        backgroundColor: (theme) =>
          status === 'live' ? theme.palette.success.lighter : theme.palette.warning.lighter,
      }}
    >
      <Typography>{translate(`wpone.general.status.${status}`)}</Typography>
    </Box>
  );
}
