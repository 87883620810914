import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
// redux
import { useLazyGetPasswordsQuery } from 'src/redux/api/siteApi';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Button } from '@mui/material';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import CopyClipboard from 'src/components/gravity/CopyClipboard';
import MaterialIcon from 'src/components/MaterialIcon';
import { TextField } from 'src/components/gravity/form';
//
import Block from 'src/sections/sites/details/Block';

// ----------------------------------------------------------------------

const GridStyle = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, minmax(120px, 1fr))',
  gridGap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, minmax(120px, 1fr))',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(1, minmax(120px, 1fr))',
  },
}));

// ----------------------------------------------------------------------

interface State {
  password: string;
  showPassword: boolean;
  copied: boolean;
}

type Props = {
  currentStatus: 'live' | 'staging';
  site: ListSiteResponseDTO | null;
};

// ----------------------------------------------------------------------

export default function DatabaseBlock({ currentStatus, site }: Props) {
  // HOOK
  const { translate } = useLocales();

  // API
  const [getPasswords, { data, isFetching, isSuccess, isError }] = useLazyGetPasswordsQuery();

  // STATE
  const [clickedCopy, setClickedCopy] = useState(false);

  const [values, setValues] = React.useState<State>({
    password: '1234',
    showPassword: false,
    copied: false,
  });

  // EVENT FUNCTION
  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = async () => {
    if (!site) return;

    if (!values.showPassword) {
      if (currentStatus === 'live') {
        if (data && data?.mysql?.production?.password) {
          setValues({
            ...values,
            showPassword: !values.showPassword,
          });
        } else {
          await getPasswords({ cluster: site.cluster.name, namespace: site.namespace });
        }
      } else {
        if (data && data?.mysql?.staging?.password) {
          setValues({
            ...values,
            showPassword: !values.showPassword,
          });
        } else {
          await getPasswords({ cluster: site.cluster.name, namespace: site.namespace });
        }
      }
    } else {
      setValues({
        ...values,
        showPassword: !values.showPassword,
      });
    }
  };

  const handleClickCopyPassword = async () => {
    if (!site) return;

    if (currentStatus === 'live') {
      if (data && data?.mysql?.production?.password) {
        setValues({
          ...values,
          copied: true,
        });

        navigator.clipboard.writeText(data.mysql.production.password);
        displayToast(translate('wpone.general.action.copied'));
      } else {
        setClickedCopy(true);

        await getPasswords({ cluster: site.cluster.name, namespace: site.namespace });
      }
    } else {
      if (data && data?.mysql?.staging?.password) {
        setValues({
          ...values,
          copied: true,
        });

        navigator.clipboard.writeText(data.mysql.staging.password);
        displayToast(translate('wpone.general.action.copied'));
      } else {
        setClickedCopy(true);

        await getPasswords({ cluster: site.cluster.name, namespace: site.namespace });
      }
    }
  };

  const handleMyPhpClick = () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'open-phpmyadmin',
    });

    window.open(site?.phpmyadmin);
  };

  //
  useEffect(() => {
    if (currentStatus === 'live') {
      if (data?.mysql?.production?.password === values.password) return;
    } else {
      if (data?.mysql?.staging?.password === values.password) return;
    }

    if (isSuccess) {
      if (currentStatus === 'live') {
        if (data?.mysql?.production?.password) {
          if (clickedCopy) {
            setValues({
              ...values,
              password: data.mysql.production.password,
              copied: true,
            });

            navigator.clipboard.writeText(data.mysql.production.password);
            displayToast(translate('wpone.general.action.copied'));

            setClickedCopy(false);
          } else {
            setValues({
              ...values,
              password: data.mysql.production.password,
              showPassword: true,
            });
          }
        } else {
          displayToast(
            translate('wpone.general.requestResponse.password.errorMessage.getCurrentPw'),
            { variant: 'alert' }
          );
        }
      } else {
        if (data?.mysql?.staging?.password) {
          if (clickedCopy) {
            setValues({
              ...values,
              password: data.mysql.staging.password,
              copied: true,
            });

            navigator.clipboard.writeText(data.mysql.staging.password);
            displayToast(translate('wpone.general.action.copied'));

            setClickedCopy(false);
          } else {
            setValues({
              ...values,
              password: data.mysql.staging.password,
              showPassword: true,
            });
          }
        } else {
          displayToast(
            translate('wpone.general.requestResponse.password.errorMessage.getCurrentPw'),
            { variant: 'alert' }
          );
        }
      }
    }

    if (isError) {
      displayToast(translate('wpone.general.requestResponse.password.errorMessage.getCurrentPw'), {
        variant: 'alert',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <Block
      title={translate('wpone.sites.details.settings.databaseAccessInfo.title')}
      displayStatus={currentStatus}
      actions={
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ ml: 2 }}>
          {/* <Button variant="outlined" onClick={handleGenerateNewDatabasePassword}>
            {translate('Generate new database password')}
          </Button> */}
          <Button
            variant="outlined"
            onClick={handleMyPhpClick}
            sx={{ textTransform: 'none', display: 'flex', flexDirection: 'row' }}
          >
            {translate('wpone.sites.details.settings.databaseAccessInfo.action')}
            <MaterialIcon icon="open_in_new" />
          </Button>
        </Stack>
      }
      content={
        <GridStyle>
          <CopyClipboard
            label={translate('wpone.general.inputField.host.label')}
            labelId={`database-host-${currentStatus}`}
            value={`${site?.database_configuration.instance || ''}`}
          />

          <CopyClipboard
            label={translate('wpone.general.inputField.name.label')}
            labelId={`database-name-${currentStatus}`}
            value={`${
              site
                ? currentStatus === 'live'
                  ? site.database_configuration.production_username || ''
                  : site.database_configuration.staging_username || ''
                : ''
            }`}
          />

          <CopyClipboard
            label={translate('wpone.general.inputField.username.label')}
            labelId={`database-username-${currentStatus}`}
            value={`${
              site
                ? currentStatus === 'live'
                  ? site.database_configuration.production_username || ''
                  : site.database_configuration.staging_username || ''
                : ''
            }`}
          />

          <TextField
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            label={translate('wpone.general.inputField.password.label')}
            labelId={`database-password-${currentStatus}`}
            nonInputElement
            onChange={handleChange('password')}
            addonSuffix={
              <>
                <button className="gv-addon" onClick={handleClickShowPassword}>
                  <gv-icon
                    src={
                      values.showPassword
                        ? '/src/icons/visibility_off.svg'
                        : '/src/icons/visibility.svg'
                    }
                  />
                </button>
                <button className="gv-addon" onClick={handleClickCopyPassword}>
                  <gv-icon src="/src/icons/content_copy.svg" />
                </button>
              </>
            }
          />
        </GridStyle>
      }
    />
  );
}
