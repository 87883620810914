// @mui
import { BoxProps, Typography } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  heading: string;
}

// ----------------------------------------------------------------------

export default function PageHeader({ heading, sx }: Props) {
  return (
    <Typography variant="h2" sx={{ mb: 5, ...sx }}>
      {heading}
    </Typography>
  );
}
