import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
import * as Yup from 'yup';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { useUpgradeStorageMutation } from 'src/redux/api/siteApi';
import { addDiskUpgradedSite } from 'src/redux/features/site';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { UserRoleEnum } from 'src/@types/user';
import { SitePlanDiskUsageEnum, SitePlanEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import { FormProvider, RHFCheckbox, RHFSelect } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type FormValuesProps = {
  size: number;
  consent: boolean;
  afterSubmit?: string;
};

type Props = {
  site: ListSiteResponseDTO | null;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function UpgradeDiskForm({ site, onClose }: Props) {
  const { name } = useParams();

  const dispatch = useDispatch();

  // HOOK
  const { translate } = useLocales();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // API
  const [upgradeStorage, { isLoading }] = useUpgradeStorageMutation();

  // VAR
  const localDiskLimit = site?.disk_space || 10;

  const planDiskLimit = site ? parseInt(SitePlanDiskUsageEnum[site.plan.name]) : 10;

  const diskSpaceOptions =
    user && localDiskLimit
      ? getDiskSpaceOptions(site?.plan.name as SitePlanEnum, localDiskLimit, user.role)
      : [];

  // FORM
  const FormSchema = Yup.object().shape({
    size: Yup.number(),
    consent: Yup.boolean().oneOf(
      [true],
      translate('wpone.sites.details.upgradeDiskDialog.consent.validation')
    ),
  });

  const defaultValues = {
    size: diskSpaceOptions[0],
    consent: false,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (!site || !name) return;

    if (localDiskLimit === data.size) {
      onClose();
      return;
    }
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: `upgrade-storage`,
    });
    await upgradeStorage({
      cluster: site.cluster.name,
      namespace: site.namespace,
      size: data.size,
    })
      .unwrap()
      .then(() => {
        dispatch(addDiskUpgradedSite({ siteName: name, isUpgradedSuccessfully: true }));
      })
      .catch(() => {
        dispatch(addDiskUpgradedSite({ siteName: name, isUpgradedSuccessfully: false }));
      })
      .finally(() => {
        onClose();
      });
  };

  // HELPER FUNCTION
  // Options display logic (note that option lower than current limit will not be displayed):
  // Customer role will only see all available options left for their plan
  // Support/Service role will see all available options up to 100
  function getDiskSpaceOptions(
    plan: SitePlanEnum,
    currentDiskLimit: number,
    userRole: UserRoleEnum
  ) {
    let optionArr: number[] = [];

    if (userRole === UserRoleEnum.customer) {
      // Customer with internal plan will not be able to upgrade disk storage
      switch (plan) {
        case SitePlanEnum.starter:
          optionArr = [10];
          break;
        case SitePlanEnum.business:
          optionArr = [10, 20];
          break;
        case SitePlanEnum.businessPremium:
          optionArr = [10, 20, 40];
          break;
        case SitePlanEnum.enterprise:
          optionArr = [10, 20, 40, 80];
          break;
      }
    } else {
      optionArr = [10, 20, 40, 80, 100];
    }

    return optionArr.filter((option) => option > currentDiskLimit);
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.upgradeDiskDialog.title')}
        </h2>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          {/*  if the checkbox is at the end of a modal, a padding 5px should be applied */}
          <div className="gv-flex-column-lg" style={{ overflowX: 'auto', paddingBottom: '5px' }}>
            {!!errors.afterSubmit?.message && (
              <Alert type="alert" text={errors.afterSubmit.message} />
            )}

            <p>
              {translate(
                `wpone.sites.details.upgradeDiskDialog.description.${
                  diskSpaceOptions.length > 1 ? 'selectable' : 'unselectable'
                }`,
                {
                  number: localDiskLimit,
                  planName: translate(`wpone.sites.plan.${site?.plan.name}`),
                  topLimit: planDiskLimit,
                }
              )}
            </p>

            {diskSpaceOptions.length > 1 && (
              <RHFSelect
                name="size"
                label={translate('wpone.sites.details.upgradeDiskDialog.inputField')}
                labelId="upgrade-disk-size"
                options={diskSpaceOptions.map((option) => ({
                  label: `${option} ${translate('wpone.general.unit.gb')}`,
                  value: option,
                }))}
              />
            )}

            <Alert
              type="warning"
              text={translate(
                `wpone.sites.details.upgradeDiskDialog.warning.${
                  diskSpaceOptions.length > 1 ? 'selectable' : 'unselectable'
                }`,
                {
                  topLimit: planDiskLimit,
                }
              )}
            />

            <RHFCheckbox
              name="consent"
              label={translate('wpone.sites.details.upgradeDiskDialog.consent.description')}
              labelId="consent"
              condensed
            />
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.general.action.confirm')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
