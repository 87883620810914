import ReactGA from 'react-ga4';
// redux
import { useUpdatePHPVersionMutation } from 'src/redux/api/siteApi';
// hooks
import useLocales from 'src/hooks/useLocales';
// @types
import { PHPVersionEnum } from 'src/@types/site';
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import AlertBanner from 'src/components/AlertBanner';
import { TextWithBoldDynamicContent, TextWithRedirectLink } from 'src/components/gravity/text';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO | null;
  onClose: VoidFunction;
  phpVersion: string;
};

// ----------------------------------------------------------------------

export default function UpdatePHPVersionConfirmation({ onClose, phpVersion, site }: Props) {
  // HOOK
  const { translate } = useLocales();

  // API
  const [updatePHPVersion, { isLoading }] = useUpdatePHPVersionMutation();

  // EVENT FUNCTION
  const handleUpdatePhpVersion = () => {
    if (!site) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'update-php-version',
    });

    updatePHPVersion({
      cluster: site.cluster.name,
      namespace: site.namespace,
      phpVersion: Number(phpVersion),
    })
      .unwrap()
      .then(() => {
        displayToast(
          translate(
            'wpone.sites.details.settings.basicInfo.updatePhpDialog.requestResponse.successMessage'
          )
        );

        onClose();
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        onClose();
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.settings.basicInfo.updatePhpDialog.title')}
        </h2>

        <div className="gv-flex-column-md" style={{ overflowX: 'auto' }}>
          <TextWithRedirectLink
            text={translate('wpone.sites.details.settings.basicInfo.updatePhpDialog.description')}
            linkHref={[
              `https://${site?.hostname}/wp-admin/site-health.php?tab=debug#health-check-accordion-block-wp-server`,
            ]}
          />

          <TextWithBoldDynamicContent
            text={translate('wpone.sites.details.settings.basicInfo.updatePhpDialog.confirmation', {
              version: phpVersion,
            })}
          />
          {phpVersion === PHPVersionEnum.PHP80 && (
            <AlertBanner severity="error">
              {translate(`wpone.sites.details.overview.banner.dbAndPhpWarning.phpWarning`, {
                deprecatedVersion: 'PHP 8.0',
                recommendedVersion: 'PHP 8.1',
              })}
            </AlertBanner>
          )}
        </div>
      </div>

      {/* Dialog header and footer style are set globally */}

      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.sites.details.settings.basicInfo.updatePhpDialog.action')}
          onClick={handleUpdatePhpVersion}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
