// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import UpdatePHPVersionConfirmation from './UpdatePHPVersionConfirmation';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  site: ListSiteResponseDTO | null;
  onClose: VoidFunction;
  phpVersion: string;
};

// ----------------------------------------------------------------------

export default function UpdadePHPVersionDialog({ open, onClose, phpVersion, site }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <UpdatePHPVersionConfirmation onClose={onClose} phpVersion={phpVersion} site={site} />
      }
    />
  );
}
