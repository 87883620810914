import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { useTriggerUpdatesMutation } from 'src/redux/api/updateApi';
// @mui
import { Stack, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
// @types
import { PerformedUpdatesResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { displayToast } from 'src/utils/handleToast';
import { convertStatusCode } from 'src/utils/convert';
//
import { UpdateTable } from './table';
import Block from 'src/sections/sites/details/Block';

// ----------------------------------------------------------------------

const ContentStyle = styled('p')(({ theme }) => ({
  maxWidth: '75%',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

// ----------------------------------------------------------------------

type Props = {
  handleViewSiteUpdate: (siteUpdate: PerformedUpdatesResponse) => void;
};

// ----------------------------------------------------------------------

export default function SiteUpdateBlock({ handleViewSiteUpdate }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  // STATE
  const [triggerUpdates, { isLoading }] = useTriggerUpdatesMutation();

  // EVENT FUNCTION
  function handleTriggerUpdate() {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'trigger-update',
    });

    triggerUpdates(name as string)
      .unwrap()
      .then((data) => {
        if (data.error === false) {
          displayToast(
            translate('wpone.sites.details.updates.table.requestResponse.successMessage')
          );
        } else {
          displayToast(translate('wpone.general.requestResponse.errorMessage.other'), {
            variant: 'alert',
          });
        }
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
      });
  }

  return (
    <Block
      title={translate('wpone.sites.details.updates.table.title')}
      content={
        <Stack spacing={5}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <ContentStyle>
              {translate('wpone.sites.details.updates.table.description')}
            </ContentStyle>
            <LoadingButton variant="contained" loading={isLoading} onClick={handleTriggerUpdate}>
              {translate('wpone.sites.details.updates.table.action')}
            </LoadingButton>
          </Stack>
          <Box justifySelf="center" width="100%">
            <UpdateTable handleViewSiteUpdate={handleViewSiteUpdate} />
          </Box>
        </Stack>
      }
    />
  );
}
