// @mui
import { Typography, Stack, StackProps } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import { Checkbox } from '../gravity/form';

// ----------------------------------------------------------------------

interface Props extends StackProps {
  actions?: React.ReactNode;
  rowCount: number;
  numSelected: number;
  onSelectAllRows: (checked: boolean) => void;
}

export default function TableSelectedActions({
  actions,
  rowCount,
  numSelected,
  onSelectAllRows,
  sx,
  ...other
}: Props) {
  const { translate } = useLocales();

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        px: 3,
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9,
        height: 58,
        position: 'absolute',
        bgcolor: 'primary.lighter',
        ...sx,
      }}
      {...other}
    >
      <Checkbox
        mixed={numSelected > 0 && numSelected < rowCount}
        checked={numSelected > 0 && numSelected <= rowCount}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onSelectAllRows(event.target.checked)
        }
      />

      <Typography
        textTransform="lowercase"
        sx={{
          ml: 2,
          flexGrow: 1,
          color: 'primary.main',
        }}
      >
        {translate('wpone.general.table.selected', { number: numSelected })}
      </Typography>

      {actions && actions}
    </Stack>
  );
}
