// @mui
import { styled } from '@mui/material/styles';
import { Stack, Button } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
// sections
import Block from 'src/sections/sites/details/Block';

// ----------------------------------------------------------------------

const ContentStyle = styled('p')(({ theme }) => ({
  maxWidth: '75%',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenDeleteSiteDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteSiteBlock({ onOpenDeleteSiteDialog }: Props) {
  const { translate } = useLocales();

  return (
    <Block
      title={translate('wpone.sites.details.settings.delete.title')}
      content={
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <ContentStyle>
            {translate('wpone.sites.details.settings.delete.description')}
          </ContentStyle>
          <Button variant="contained" onClick={onOpenDeleteSiteDialog}>
            {translate('wpone.sites.details.settings.delete.action')}
          </Button>
        </Stack>
      }
    />
  );
}
