// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import DeleteSiteForm from './DeleteSiteForm';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO | null;
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteSiteDialog({ site, open, onClose }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<DeleteSiteForm site={site} onClose={onClose} />}
    />
  );
}
