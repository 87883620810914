// hooks
import useLocales from 'src/hooks/useLocales';
// components
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type Props = {
  dbUsage: number;
  dbLimit: number;
  phpVersion: number;
};

// ----------------------------------------------------------------------

export default function DatabaseAndPhpWarningBanner({ dbUsage, dbLimit, phpVersion }: Props) {
  // HOOK
  const { translate } = useLocales();

  // VAR
  const dbUsageRatioStatus =
    dbUsage / dbLimit >= 1 ? 'alert' : dbUsage / dbLimit >= 0.8 ? 'warning' : 'good';

  const phpVersionStatus = phpVersion === 8 || phpVersion === 7.4 ? 'alert' : 'good';

  if (phpVersionStatus === 'good') {
    return (
      <Alert
        type="warning"
        text={translate(
          `wpone.sites.details.overview.banner.dbAndPhpWarning.${
            dbUsageRatioStatus === 'warning' ? 'databaseStatusWarning' : 'databaseStatusError'
          }`
        )}
      />
    );
  } else if (dbUsageRatioStatus === 'good') {
    return (
      <Alert
        type="alert"
        text={translate(`wpone.sites.details.overview.banner.dbAndPhpWarning.phpWarning`, {
          deprecatedVersion: `PHP ${phpVersion.toFixed(1).toString()}`,
          recommendedVersion: 'PHP 8.1',
        })}
      />
    );
  } else {
    return (
      <div className={`gv-notice gv-notice-warning`}>
        <gv-icon class="gv-notice-icon" src={`/src/icons/warning.svg`} />

        <ul className="gv-notice-content">
          <li>
            {translate(
              `wpone.sites.details.overview.banner.dbAndPhpWarning.${
                dbUsageRatioStatus === 'warning' ? 'databaseStatusWarning' : 'databaseStatusError'
              }`
            )}
          </li>
          <li>
            {translate(`wpone.sites.details.overview.banner.dbAndPhpWarning.phpWarning`, {
              deprecatedVersion: `PHP ${phpVersion.toFixed(1).toString()}`,
              recommendedVersion: 'PHP 8.1',
            })}
          </li>
        </ul>
      </div>
    );
  }
}
