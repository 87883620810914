import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { useLazyGetPasswordsQuery, useGenerateSSHPasswordMutation } from 'src/redux/api/siteApi';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { PasswordState } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { TextField } from 'src/components/gravity/form';
import CopyClipboard from 'src/components/gravity/CopyClipboard';
//
import Block from 'src/sections/sites/details/Block';

// ----------------------------------------------------------------------

const GridStyle = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, minmax(120px, 1fr))',
  gridGap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, minmax(120px, 1fr))',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(1, minmax(120px, 1fr))',
  },
}));

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO | null;
};

// ----------------------------------------------------------------------

export default function SecureShellBlock({ site }: Props) {
  const { cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  // API
  const [
    getPasswords,
    {
      data: passwords,
      isFetching: getPasswordsIsFetching,
      isSuccess: getPasswordsIsSuccess,
      isError: getPasswordsIsError,
    },
  ] = useLazyGetPasswordsQuery();

  const [
    generateSSHPassword,
    {
      data: generateSSHPasswordData,
      isLoading: generateSSHPasswordIsLoading,
      isSuccess: generateSSHPasswordIsSuccess,
      isError: generateSSHPasswordIsError,
      error: generateSSHPasswordError,
    },
  ] = useGenerateSSHPasswordMutation();

  // STATE
  const [clickedCopy, setClickedCopy] = useState(false);

  const [values, setValues] = useState<PasswordState>({
    password: '1234',
    showPassword: false,
    copied: false,
  });

  // EVENT FUNCTION
  const handleClickShowPassword = async () => {
    if (!site) return;

    if (!values.showPassword) {
      if (passwords) {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
      } else {
        await getPasswords({ cluster: site.cluster.name, namespace: site.namespace });
      }
    } else {
      setValues({
        ...values,
        showPassword: !values.showPassword,
      });
    }
  };

  const handleClickCopyPassword = async () => {
    if (!site) return;

    if (passwords) {
      setValues({
        ...values,
        copied: true,
      });

      navigator.clipboard.writeText(passwords.ssh_password);
      displayToast(translate('wpone.general.action.copied'));
    } else {
      setClickedCopy(true);
      await getPasswords({ cluster: site.cluster.name, namespace: site.namespace });
    }
  };

  const handleGenerateNewSFTPPassword = async () => {
    if (!namespace || !cluster) return;
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'generate-new-sftp-password',
    });
    await generateSSHPassword({ cluster: cluster, namespace: namespace });
  };

  //
  // useEffect handles generateNewPw request
  useEffect(() => {
    if (generateSSHPasswordIsSuccess) {
      if (generateSSHPasswordData?.ssh_password) {
        displayToast(translate('wpone.general.requestResponse.password.successMessage.generatePw'));
      } else {
        displayToast(translate('wpone.general.requestResponse.errorMessage.other'), {
          variant: 'alert',
        });
      }
    }

    if (generateSSHPasswordIsError) {
      const errCode = (generateSSHPasswordError as { status: number; data: any }).status;
      displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateSSHPasswordIsLoading]);

  // useEffect handles getCurrentPw request
  useEffect(() => {
    if (passwords?.ssh_password === values.password) return;

    if (getPasswordsIsSuccess) {
      if (passwords?.ssh_password) {
        if (clickedCopy) {
          setValues({
            ...values,
            password: passwords.ssh_password,
            copied: true,
          });

          navigator.clipboard.writeText(passwords.ssh_password);
          displayToast(translate('wpone.general.action.copied'));

          setClickedCopy(false);
        } else {
          setValues({
            ...values,
            password: passwords.ssh_password,
            showPassword: true,
          });
        }
      } else {
        displayToast(
          translate('wpone.general.requestResponse.password.errorMessage.getCurrentPw'),
          { variant: 'alert' }
        );
      }
    }

    if (getPasswordsIsError) {
      displayToast(translate('wpone.general.requestResponse.password.errorMessage.getCurrentPw'), {
        variant: 'alert',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPasswordsIsFetching]);

  return (
    <Block
      title={translate('wpone.sites.details.settings.sshInfo.title')}
      description={'wpone.sites.details.settings.sshInfo.tooltip'}
      actions={
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <LoadingButton
            variant="outlined"
            onClick={handleGenerateNewSFTPPassword}
            loading={generateSSHPasswordIsLoading}
          >
            {translate('wpone.sites.details.settings.sshInfo.action')}
          </LoadingButton>
        </Stack>
      }
      content={
        <>
          <GridStyle>
            <CopyClipboard
              label={translate('wpone.general.inputField.host.label')}
              labelId="host"
              value={`${site ? site.ssh_configuration.hostname : ''}`}
            />

            <CopyClipboard
              label={translate('wpone.general.inputField.username.label')}
              labelId="username"
              value={`${site ? site.ssh_configuration.user : ''}`}
            />

            <TextField
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              label={translate('wpone.general.inputField.password.label')}
              labelId="password"
              nonInputElement
              addonSuffix={
                <>
                  <button className="gv-addon" onClick={handleClickShowPassword}>
                    <gv-icon
                      src={
                        values.showPassword
                          ? '/src/icons/visibility_off.svg'
                          : '/src/icons/visibility.svg'
                      }
                    />
                  </button>
                  <button className="gv-addon" onClick={handleClickCopyPassword}>
                    <gv-icon src="/src/icons/content_copy.svg" />
                  </button>
                </>
              }
            />

            <CopyClipboard
              label={translate('wpone.sites.details.settings.sshInfo.inputField.port.label')}
              labelId="port"
              value={`${site ? site.ssh_configuration.port : ''}`}
            />
          </GridStyle>

          <Box sx={{ mt: 2 }}>
            <CopyClipboard
              label={translate(
                'wpone.sites.details.settings.sshInfo.inputField.sshTerminalCommand.label'
              )}
              labelId="ssh-terminal-command"
              value={
                site
                  ? `ssh -p${site.ssh_configuration.port} ${site.ssh_configuration.user}@${site.ssh_configuration.hostname}`
                  : ''
              }
            />
          </Box>
        </>
      }
    />
  );
}
