import { m } from 'framer-motion';
import { useRef, useState } from 'react';
import Slider from 'react-slick';
import ReactGA from 'react-ga4';
// redux
import { useSelector } from 'src/redux/store';
// @mui
import { Box, Container, Typography, Card, Link } from '@mui/material';
// @types
import { VisualDiffs } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import Image from 'src/components/Image';
import LightboxModal from 'src/components/LightboxModal';
import CarouselArrows from 'src/components/CarouselArrows';
import { MotionViewport, varFade } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function IssueSlider() {
  const { translate } = useLocales();

  const carouselRef = useRef<Slider>(null);

  const { currentIssue } = useSelector((state) => state.site);

  const [openLightbox, setOpenLightbox] = useState(false);

  const [lightboxMainSrc, setLightboxMainSrc] = useState('');

  const handleOpenLightbox = (url: string) => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'open-lightbox',
    });

    setLightboxMainSrc(url);
    setOpenLightbox(true);
  };

  const settings = {
    arrows: false,
    slidesToShow: 1,
    centerMode: true,
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Container component={MotionViewport} sx={{ textAlign: 'center', overflow: 'hidden' }}>
      <m.div variants={varFade().inDown}>
        <Typography
          component="p"
          variant="body2"
          textTransform="uppercase"
          sx={{ mb: 2, color: 'text.secondary' }}
        >
          {translate('wpone.sites.details.updates.report.updateIssueDialog.description')}
        </Typography>
      </m.div>
      <Box sx={{ position: 'relative' }}>
        {currentIssue.length !== 0 &&
          (currentIssue.length === 1 ? (
            <Slider ref={carouselRef} {...settings}>
              {currentIssue.map((issue) => (
                <Box key={issue.id} component={m.div} variants={varFade().in} sx={{ px: 1.5 }}>
                  <IssueCard issue={issue} handleOpenLightbox={handleOpenLightbox} />
                </Box>
              ))}
            </Slider>
          ) : (
            <CarouselArrows filled onNext={handleNext} onPrevious={handlePrevious}>
              <Slider ref={carouselRef} {...settings}>
                {currentIssue.map((issue) => (
                  <Box key={issue.id} component={m.div} variants={varFade().in} sx={{ px: 1.5 }}>
                    <IssueCard issue={issue} handleOpenLightbox={handleOpenLightbox} />
                  </Box>
                ))}
              </Slider>
            </CarouselArrows>
          ))}
      </Box>

      <LightboxModal
        mainSrc={lightboxMainSrc}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </Container>
  );
}

// ----------------------------------------------------------------------

type IssueCardProps = {
  issue: VisualDiffs;
  handleOpenLightbox: (img: string) => void;
};

function IssueCard({ issue, handleOpenLightbox }: IssueCardProps) {
  const { id, url, thumb } = issue;
  return (
    <Card key={id} sx={{ p: 1 }}>
      <Link sx={{ color: 'text.secondary' }} href={url} target="_blank">
        {url}
      </Link>
      <Image
        alt={url}
        src={thumb}
        onClick={() => handleOpenLightbox(thumb)}
        sx={{
          maxWidth: 700,
          height: 'auto',
          borderRadius: 1.5,
          cursor: 'zoom-in',
          minHeight: 300,
          mt: 2,
        }}
      />
    </Card>
  );
}
