// @mui
import { Box, IconButton, TablePagination, TablePaginationProps, useTheme } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import MaterialIcon from 'src/components/MaterialIcon';

// ----------------------------------------------------------------------

export default function TablePaginationCustom(props: TablePaginationProps) {
  // HOOK
  const { translate } = useLocales();

  return (
    <TablePagination
      component={'div'}
      labelRowsPerPage={translate('wpone.general.table.rowNumber')}
      labelDisplayedRows={({ from, to, count }) => `${from}–${to} / ${count}`}
      {...props}
      SelectProps={{
        IconComponent: (props) => (
          <MaterialIcon
            icon="keyboard_arrow_down"
            sx={{
              top: 'calc(50% - 12px) !important',
            }}
            {...props}
          />
        ),
      }}
      ActionsComponent={TablePaginationActions}
    />
  );
}

// ----------------------------------------------------------------------

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

// ----------------------------------------------------------------------

export function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? (
          <MaterialIcon icon="chevron_right" />
        ) : (
          <MaterialIcon icon="chevron_left" />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <MaterialIcon icon="chevron_left" />
        ) : (
          <MaterialIcon icon="chevron_right" />
        )}
      </IconButton>
    </Box>
  );
}
