// hooks
import useLocales from 'src/hooks/useLocales';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
import Button from 'src/components/gravity/Button';
//
import IssueSlider from './IssueSlider';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function UpdateIssueDialog({ open, onClose }: Props) {
  const { translate } = useLocales();

  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <>
          <div className="gv-modal-body">
            <h2 className="gv-modal-title">
              {translate('wpone.sites.details.updates.report.updateIssueDialog.title')}
            </h2>
            <div style={{ overflowX: 'auto' }}>
              <IssueSlider />
            </div>
          </div>

          {/* Dialog header and footer style are set globally */}

          <Button
            text={translate('wpone.general.action.cancel')}
            uiType="cancel"
            onClick={onClose}
          />
        </>
      }
    />
  );
}
