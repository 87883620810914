import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { useResetSiteMutation } from 'src/redux/api/siteApi';
import { useSelector } from 'src/redux/store';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import AlertBanner from 'src/components/AlertBanner';
import { TextField } from 'src/components/gravity/form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO | null;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function ResetSiteConfirmation({ site, onClose }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  // API
  const [resetSite, { isLoading }] = useResetSiteMutation();

  // STATE
  const { user } = useSelector((state) => state.auth);

  const [confirmText, setConfirmText] = useState('');

  // EVENT FUNCTION
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmText(event.target.value);
  };

  const handleResetSite = () => {
    if (!site || !user?.wpInstallOptions?.adminEmail) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'reset-site',
    });

    resetSite({
      cluster: site.cluster.name,
      namespace: site.namespace,
      wpAdminEmail: user.wpInstallOptions.adminEmail,
    })
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          displayToast(
            translate('wpone.sites.details.settings.reset.resetSiteDialog.toast.success')
          );
        } else {
          displayToast(
            translate('wpone.sites.details.settings.reset.resetSiteDialog.toast.error'),
            {
              variant: 'alert',
            }
          );
        }
        onClose();
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        onClose();
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.settings.reset.resetSiteDialog.title')}
        </h2>
        <div className="gv-flex-column-lg" style={{ overflowX: 'auto' }}>
          <p>
            {translate('wpone.sites.details.settings.reset.resetSiteDialog.description', {
              siteName: name,
            })}
          </p>
          <p className="gv-text-lg">
            {translate('wpone.sites.details.settings.reset.resetSiteDialog.confirmation', {
              siteName: name,
            })}
          </p>

          <TextField
            label={translate('wpone.sites.details.settings.reset.resetSiteDialog.inputField.label')}
            labelId="site-name-reset"
            placeholder={name}
            value={confirmText}
            onChange={handleChange}
          />

          <AlertBanner severity="info">
            <p>{translate('wpone.sites.details.settings.reset.resetSiteDialog.tip')}</p>
          </AlertBanner>
        </div>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          uiType="destructive"
          text={translate('wpone.sites.details.settings.reset.resetSiteDialog.action')}
          onClick={handleResetSite}
          disabled={confirmText !== `${name}` || isLoading}
        />
      </ButtonGroup>
    </>
  );
}
