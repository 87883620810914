import { Dispatch, SetStateAction } from 'react';
// redux
import { useSelector } from 'src/redux/store';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { UserRoleEnum } from 'src/@types/user';
import { PHPVersionEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import CopyClipboard from 'src/components/gravity/CopyClipboard';
import { Select, TextField } from 'src/components/gravity/form';
//
import Block from 'src/sections/sites/details/Block';

// ----------------------------------------------------------------------

const GridStyle = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(120px, 1fr))',
  gridGap: '16px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, minmax(120px, 1fr))',
  },
}));

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO | null;
  onOpenUpdatePHPVersionDialog: VoidFunction;
  setPhpversion: Dispatch<SetStateAction<string>>;
};

// ----------------------------------------------------------------------

export default function BasicBlock({ site, onOpenUpdatePHPVersionDialog, setPhpversion }: Props) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // EVENT FUNCTION
  const handlePhpSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPhpversion(event.target.value);
    onOpenUpdatePHPVersionDialog();
  };

  // HELPER FUNCTION
  function getVersionList(userRole: UserRoleEnum, currentPHPVersion: number): PHPVersionEnum[] {
    /*
    PHP version 7.4 display:
      - Customers who are using PHP 7.4 will return PHP version: 7.4, 8.1, 8.2, 8.3
      - Customers who are NOT using PHP 7.4 will not see it in seletion list so return 8.1, 8.2, 8.3
      - Support and service account will see all PHP version but 7.4 is disabled
    PHP version 8.0 display:
      - Customers who are using PHP 8.0 will return 8.0, 8.1, 8.2, 8.3
      - Customers who are NOT using PHP 8.0 will not see it in seletion list so return 8.1, 8.2, 8.3
      - Support and service account will see all PHP version except 7.4
    Other PHP version are all displayed in selection except 7.4
    */
    if (userRole === UserRoleEnum.customer) {
      if (currentPHPVersion === 7.4) {
        return [
          PHPVersionEnum.PHP74,
          PHPVersionEnum.PHP81,
          PHPVersionEnum.PHP82,
          PHPVersionEnum.PHP83,
        ];
      } else if (currentPHPVersion === 8) {
        return [
          PHPVersionEnum.PHP80,
          PHPVersionEnum.PHP81,
          PHPVersionEnum.PHP82,
          PHPVersionEnum.PHP83,
        ];
      } else {
        return [PHPVersionEnum.PHP81, PHPVersionEnum.PHP82, PHPVersionEnum.PHP83];
      }
    } else {
      if (currentPHPVersion === 7.4) {
        return [
          PHPVersionEnum.PHP74,
          PHPVersionEnum.PHP80,
          PHPVersionEnum.PHP81,
          PHPVersionEnum.PHP82,
          PHPVersionEnum.PHP83,
        ];
      } else {
        return [
          PHPVersionEnum.PHP80,
          PHPVersionEnum.PHP81,
          PHPVersionEnum.PHP82,
          PHPVersionEnum.PHP83,
        ];
      }
    }
  }

  return (
    <Block
      title={translate('wpone.sites.details.settings.basicInfo.title')}
      content={
        <GridStyle>
          <TextField
            label={translate('wpone.sites.details.settings.basicInfo.inputField.location.label')}
            labelId="location"
            value={site ? site.cluster.location : ''}
            nonInputElement
          />
          {/* PHP version 7.4 display:
                - Customers who are using PHP 7.4 will see it as disabled
                - Customers who are NOT using PHP 7.4 will not see it in seletion list
                - Support/Service will see all PHP options
              */}
          {user?.role && site?.php_version && (
            <Select
              label={translate('wpone.general.inputField.phpVersion.label')}
              labelId="php-version"
              value={site.php_version.toFixed(1)}
              options={getVersionList(user.role, site.php_version).map((option) => ({
                label: option,
                value: option,
                disabled:
                  option === PHPVersionEnum.PHP74 ||
                  (user.role === UserRoleEnum.customer && option === PHPVersionEnum.PHP80),
              }))}
              onChange={handlePhpSelect}
            />
          )}
          <CopyClipboard
            label={translate('wpone.sites.details.settings.basicInfo.inputField.cluster.label')}
            labelId="cluster"
            value={site?.cluster.name || ''}
          />
          <CopyClipboard
            label={translate('wpone.sites.details.settings.basicInfo.inputField.ipAddress.label')}
            labelId="ip-address"
            value={site?.cluster.address || ''}
            labelIcon="help"
            labelIconTooltip={translate(
              'wpone.sites.details.settings.basicInfo.inputField.ipAddress.tooltip'
            )}
          />
          <CopyClipboard
            label={translate('wpone.sites.details.settings.basicInfo.inputField.path.label')}
            labelId="path"
            value={site?.installation_path || ''}
          />
          <CopyClipboard
            label={translate(
              'wpone.sites.details.settings.basicInfo.inputField.outgoingIpAddress.label'
            )}
            labelId="ip-address-outgoing-traffic"
            value="34.88.101.71, 34.88.138.128, 34.88.89.12, 35.228.1.6"
            labelIcon="help"
            labelIconTooltip={translate(
              'wpone.sites.details.settings.basicInfo.inputField.outgoingIpAddress.tooltip'
            )}
          />
        </GridStyle>
      }
    />
  );
}
