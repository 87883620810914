// @mui
import { Stack, Button, Typography } from '@mui/material';
// @types
import { PerformedUpdatesResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import Block from 'src/sections/sites/details/Block';
import UpdateDetailsTable from './table/UpdateDetailsTable';

// ----------------------------------------------------------------------

type Props = {
  siteUpdate: PerformedUpdatesResponse;
  handleBackToUpdateList: VoidFunction;
  onOpen: VoidFunction;
};

// ----------------------------------------------------------------------

const UpdateDetailsBlock = ({ siteUpdate, handleBackToUpdateList, onOpen }: Props) => {
  const { translate } = useLocales();
  const data = siteUpdate;

  return (
    <Block
      title={translate('wpone.sites.details.updates.report.title')}
      displayStatus={null}
      actions={
        <Button variant="outlined" onClick={handleBackToUpdateList}>
          {translate('wpone.sites.details.updates.report.action')}
        </Button>
      }
      content={
        <Stack spacing={5}>
          <Typography textAlign="center">
            {new Date(data.update_attempted_on).toLocaleString('fi-FI').replace(/\//g, '.')}
          </Typography>
          {data.updated_wp && (
            <UpdateDetailsTable name="wp" data={data.updated_wp} onOpen={onOpen} />
          )}
          {data.updated_plugins && (
            <UpdateDetailsTable name="plugin" data={data.updated_plugins} onOpen={onOpen} />
          )}
          {data.updated_themes && (
            <UpdateDetailsTable name="theme" data={data.updated_themes} onOpen={onOpen} />
          )}
        </Stack>
      }
    />
  );
};

export default UpdateDetailsBlock;
