import ReactGA from 'react-ga4';
// redux
import { useDispatch } from 'src/redux/store';
import { setCurrentIssue } from 'src/redux/features/site';
// @mui
import { TableRow, TableCell, Typography, Stack, Button } from '@mui/material';
// @types
import { UpdatedDetails } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import Iconify from 'src/components/Iconify';
import MaterialIcon from 'src/components/MaterialIcon';

// ----------------------------------------------------------------------

type Props = {
  row: UpdatedDetails;
  onOpen: VoidFunction;
  header?: string;
};

// ----------------------------------------------------------------------

export default function UpdateDetailsTableRow({ row, onOpen, header }: Props) {
  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { title, reverted, version_from, version_to, version_attempted, visual_diffs } = row;

  const openIssueHandler = () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'open-update-issue-dialog',
    });

    dispatch(setCurrentIssue(visual_diffs));
    onOpen();
  };

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="body2">{header ? header : title}</Typography>
      </TableCell>

      <TableCell sx={{ maxWidth: 250 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {!reverted ? (
            <>
              <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ color: 'success.main' }} />
              <Typography variant="body2">
                {translate('wpone.sites.details.updates.report.table.row.status.success')}
              </Typography>
            </>
          ) : (
            <>
              <Iconify icon={'eva:alert-circle-fill'} sx={{ color: 'error.main' }} />
              <Typography variant="body2">
                {translate('wpone.sites.details.updates.report.table.row.status.failed')}
              </Typography>
            </>
          )}
        </Stack>
      </TableCell>

      <TableCell sx={{ maxWidth: 250 }}>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">{version_from}</Typography>
            <MaterialIcon icon="arrow_forward" />
            <Typography variant="body2">{reverted ? version_attempted : version_to}</Typography>
          </Stack>
          {reverted && (
            <Typography variant="body2">{`(${translate(
              'wpone.sites.details.updates.report.table.row.version'
            )})`}</Typography>
          )}
        </Stack>
      </TableCell>

      <TableCell align="right" sx={{ maxWidth: 250 }}>
        {visual_diffs && (
          <Button variant="outlined" onClick={openIssueHandler}>
            {translate('wpone.sites.details.updates.report.table.row.action')}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}
