import { ReactNode } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Stack, Typography, IconButton, Link } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import Page from './Page';
import PageHeader from './PageHeader';
import MaterialIcon from '../MaterialIcon';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  heading: string;
  backRoute: string;
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function ActionPage({ title, heading, backRoute, children }: Props) {
  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  return (
    <Page title={translate(title)}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{ mb: 3 }}
      >
        <IconButton onClick={() => navigate(backRoute)} sx={{ p: 0.5 }}>
          <MaterialIcon icon="arrow_back" sx={{ color: '#8A8989' }} />
        </IconButton>
        <Link component={RouterLink} to={backRoute} underline="none" sx={{ color: '#8A8989' }}>
          <Typography lineHeight={'32px'}>{translate('wpone.general.action.back')}</Typography>
        </Link>
      </Stack>
      <PageHeader heading={translate(heading)} sx={{ mb: 3 }} />
      {children}
    </Page>
  );
}
