import { useParams } from 'react-router';
// redux
import { useGetUpdatesQuery } from 'src/redux/api/updateApi';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Table, TableBody, TableContainer } from '@mui/material';
// @types
import { PerformedUpdatesResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
// components
import Scrollbar from 'src/components/Scrollbar';
import { TableNoData, TableHeadCustom, TableSkeleton } from 'src/components/table';
//
import UpdateTableRow from './UpdateTableRow';

// ----------------------------------------------------------------------

const SubtableStyle = styled(Card)(({ theme }) => ({
  maxWidth: 'calc(100% + 80px)',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '-40px !important',
    marginRight: '-40px !important',
    marginBottom: '-32px !important',
    borderRadius: 0,
  },
}));

// ----------------------------------------------------------------------

// For table column width management:
// - maxWidth in TableCell and minWidth in main table for preventing shifting header
// - width in main table for preventing auto expand when there are extra space (due to few cols selected...). But one must not be set, else all columns will be auto expanded
const TABLE_HEAD = [
  { id: 'date', label: 'wpone.sites.details.updates.table.header.date', minWidth: 250, width: 250 },
  {
    id: 'status',
    label: 'wpone.sites.details.updates.table.header.status',
    minWidth: 250,
    width: 250,
  },
  {
    id: 'message',
    label: 'wpone.sites.details.updates.table.header.message',
    minWidth: 250,
  },
  {
    id: 'action',
    label: '',
    align: 'right',
    minWidth: 230,
    width: 230,
  },
];

type Props = {
  handleViewSiteUpdate: (siteUpdate: PerformedUpdatesResponse) => void;
};

// ----------------------------------------------------------------------

export default function UpdateTable({ handleViewSiteUpdate }: Props) {
  const { name } = useParams();

  // API
  const { data, isFetching } = useGetUpdatesQuery(name);

  // VAR
  const tableData = data?.data.updateList || [];
  const isNotFound = !tableData?.length;

  return (
    <SubtableStyle>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table>
            <TableHeadCustom headLabel={TABLE_HEAD} />

            <TableBody>
              {isFetching ? (
                <TableSkeleton checkbox={false} headers={TABLE_HEAD} />
              ) : (
                <>
                  {tableData.map((row) => (
                    <UpdateTableRow
                      key={row.id}
                      row={row}
                      handleViewSiteUpdate={handleViewSiteUpdate}
                    />
                  ))}
                  <TableNoData isNotFound={isNotFound} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </SubtableStyle>
  );
}
