import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

type SplitText = {
  text: string;
  type: 'a' | 'p'; // 'a' for text inside <a> tags, 'p' for text outside <a> tags
  link?: string; // Only present for 'a' type, represents the link
};

type TextWithNavigationLinkProps = {
  text: string;
  fontSize?: 'xl' | 'lg' | 'md' | 'sm';
  linkHrefs: string[];
  linkStyleClassName?: string;
};

// ----------------------------------------------------------------------

export default function TextWithNavigationLink({
  text,
  fontSize = 'sm',
  linkHrefs,
  linkStyleClassName = '',
}: TextWithNavigationLinkProps) {
  // VAR
  const parsedTextArr = splitStringWithTagsAndLinks(text, linkHrefs);

  // HELPER FUNCTION
  function splitStringWithTagsAndLinks(text: string, links: string[]): SplitText[] {
    const result: SplitText[] = [];
    let currentIndex = 0;
    let linkIndex = 0;
    let match;

    const pattern = /<a>(.*?)<\/a>/g;

    while ((match = pattern.exec(text)) !== null) {
      // Add text before <a> tag
      if (match.index > currentIndex) {
        const textBefore = text.substring(currentIndex, match.index);
        if (textBefore) {
          result.push({ text: textBefore, type: 'p' });
        }
      }

      // Add text inside <a> tag
      const link = links[linkIndex] || ''; // Assign link or empty string if no more links available
      result.push({ text: match[1], type: 'a', link });
      linkIndex++;

      currentIndex = pattern.lastIndex;
    }

    // Add text after last </a> tag if any
    if (currentIndex < text.length) {
      const textAfter = text.substring(currentIndex);
      if (textAfter) {
        result.push({ text: textAfter, type: 'p' });
      }
    }

    return result;
  }

  return (
    <p className={`gv-text-${fontSize}`}>
      {parsedTextArr.map((item, index) => {
        if (item.link === undefined) {
          return item.text;
        } else {
          return (
            <RouterLink key={index} className={linkStyleClassName} to={item.link}>
              {item.text}
            </RouterLink>
          );
        }
      })}
    </p>
  );
}
