// @mui
import { styled } from '@mui/material/styles';
import { Button, Stack } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
// sections
import Block from 'src/sections/sites/details/Block';

// ----------------------------------------------------------------------

const ContentStyle = styled('p')(({ theme }) => ({
  maxWidth: '75%',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenReactivateSiteDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function ReactivateSiteBlock({ onOpenReactivateSiteDialog }: Props) {
  const { translate } = useLocales();

  return (
    <Block
      title={translate('wpone.sites.details.settings.reactivate.title')}
      content={
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <ContentStyle>
            {translate('wpone.sites.details.settings.reactivate.description')}
          </ContentStyle>
          <Button
            variant="contained"
            onClick={onOpenReactivateSiteDialog}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {translate('wpone.sites.details.settings.reactivate.action')}
          </Button>
        </Stack>
      }
    />
  );
}
