// @mui
import { Theme } from '@mui/material/styles';
import {
  Box,
  SxProps,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
  Tooltip,
} from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import MaterialIcon from 'src/components/MaterialIcon';
import { Checkbox } from '../gravity/form';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

// ----------------------------------------------------------------------

type Props = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  sx,
}: Props) {
  const { translate } = useLocales();

  return (
    <TableHead sx={sx}>
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              mixed={numSelected > 0 && numSelected < rowCount}
              checked={numSelected > 0 && numSelected <= rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
            />
          </TableCell>
        )}

        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              whiteSpace: 'nowrap',
            }}
          >
            <Tooltip
              title={translate(headCell?.tooltip || '')}
              placement="top"
              PopperProps={{ sx: { textAlign: 'center' } }}
            >
              {onSort && headCell.sorting ? (
                <TableSortLabel
                  hideSortIcon
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => onSort(headCell.id)}
                  IconComponent={(props) => (
                    <MaterialIcon
                      icon="keyboard_arrow_down"
                      sx={{
                        top: 'calc(50% - 12px) !important',
                      }}
                      {...props}
                    />
                  )}
                >
                  <Typography>{translate(headCell.label)}</Typography>

                  {orderBy === headCell.id ? (
                    <Box sx={{ ...visuallyHidden }}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <Typography>{translate(headCell.label)}</Typography>
              )}
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
