// @mui
import { TableRow, TableCell, Typography, Stack, Button } from '@mui/material';
// @types
import { PerformedUpdatesResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fShortDateTime } from 'src/utils/format';
// components
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

type Props = {
  row: PerformedUpdatesResponse;
  handleViewSiteUpdate: (siteUpdate: PerformedUpdatesResponse) => void;
};

export default function UpdateTableRow({ row, handleViewSiteUpdate }: Props) {
  // HOOK
  const { translate, currentLang } = useLocales();

  // VAR
  const { update_attempted_on, total_updates, updated_wp, updated_themes, updated_plugins } = row;

  // There can be cases where updated_wp, updated_themes, updated_plugins is empty obj (which can be considered as null)
  const checkedUpdatedWp = !updated_wp || Object.keys(updated_wp).length === 0 ? null : updated_wp;
  const checkedUpdatedThemes =
    !updated_themes || Object.keys(updated_themes).length === 0 ? null : updated_themes;
  const checkedUpdatedPlugins =
    !updated_plugins || Object.keys(updated_plugins).length === 0 ? null : updated_plugins;

  const displayRow = updateDisplayHandler();

  // HELPER FUNCTION
  function updateDisplayHandler() {
    const result: { messages: string[]; status: 'good' | 'warning' } = {
      messages: [],
      status: 'good',
    };

    if (!checkedUpdatedWp && !checkedUpdatedThemes && !checkedUpdatedPlugins) {
      return {
        ...result,
        messages: [translate('wpone.sites.details.updates.table.row.message.noUpdates')],
      };
    }

    // Prevent case wher total_updates is null | undefined
    if (!total_updates) {
      return {
        messages: [translate('wpone.sites.details.updates.table.row.message.notSuccessful')],
        status: 'warning',
      };
    }

    if (checkedUpdatedWp) {
      if (!checkedUpdatedWp.reverted) {
        result.messages.push(
          translate('wpone.sites.details.updates.table.row.message.wpCoreUpdated')
        );
      }
    }

    if (checkedUpdatedThemes) {
      const totalThemeUpdatesAttempted = Object.values(checkedUpdatedThemes).length;
      const totalThemeUpdatedFail = Object.values(checkedUpdatedThemes).filter(
        (update) => update.reverted === true
      ).length;
      const totalThemeUpdatedSuccessfully = totalThemeUpdatesAttempted - totalThemeUpdatedFail;

      result.messages.push(
        translate('wpone.sites.details.updates.table.row.message.themeUpdated', {
          updated: totalThemeUpdatedSuccessfully,
          attempted: totalThemeUpdatesAttempted,
        })
      );
    }

    if (checkedUpdatedPlugins) {
      const totalPluginUpdatesAttempted = Object.values(checkedUpdatedPlugins).length;
      const totalPluginUpdatedFail = Object.values(checkedUpdatedPlugins).filter(
        (update) => update.reverted === true
      ).length;
      const totalPluginUpdatedSuccessfully = totalPluginUpdatesAttempted - totalPluginUpdatedFail;

      result.messages.push(
        translate('wpone.sites.details.updates.table.row.message.pluginUpdated', {
          updated: totalPluginUpdatedSuccessfully,
          attempted: totalPluginUpdatesAttempted,
        })
      );
    }

    return result;
  }

  return (
    <TableRow hover>
      <TableCell sx={{ maxWidth: 250 }}>
        <Typography variant="body2" noWrap>
          {fShortDateTime(new Date(update_attempted_on), currentLang.value)}
        </Typography>
      </TableCell>

      <TableCell sx={{ maxWidth: 250 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {displayRow.status === 'good' ? (
            <>
              <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ color: 'success.main' }} />
              <Typography variant="body2">
                {translate('wpone.sites.details.updates.table.row.status.good')}
              </Typography>
            </>
          ) : (
            <>
              <Iconify icon={'eva:alert-circle-fill'} sx={{ color: 'warning.main' }} />
              <Typography variant="body2">
                {translate('wpone.sites.details.updates.table.row.status.warning')}
              </Typography>
            </>
          )}
        </Stack>
      </TableCell>

      <TableCell>
        <Stack spacing={2}>
          {displayRow.messages.map((message, index) => (
            <Typography key={index} variant="body2">
              {message}
            </Typography>
          ))}
        </Stack>
      </TableCell>

      <TableCell align="right" sx={{ maxWidth: 230 }}>
        {(checkedUpdatedWp || checkedUpdatedThemes || checkedUpdatedPlugins) && (
          <Button
            sx={{ textTransform: 'none' }}
            variant="outlined"
            onClick={() => handleViewSiteUpdate(row)}
          >
            {translate('wpone.sites.details.updates.table.row.action')}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}
