import { Dispatch, SetStateAction } from 'react';
import { useNavigate, useParams } from 'react-router';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { setUpgradePlanDetails } from 'src/redux/features/plan';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { SitePlanEnum } from 'src/@types/site';
import { UserRoleEnum, AccessTypeEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';
import useHighestUserRole from 'src/hooks/useHighestUserRole';
// utils
import { isHostnetCustomer } from 'src/utils/check';
// components
import Alert from 'src/components/gravity/Alert';
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
  plan: SitePlanEnum;
  localDiskLimit: number;
  planDiskLimit: number;
  fromTab: string;
  setDisplayDiskUsageWarning: Dispatch<SetStateAction<boolean>>;
  onOpenCustomPlanDialog: VoidFunction;
  onOpenUpgradeDiskDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DiskUsageWarningBanner({
  site,
  plan,
  localDiskLimit,
  planDiskLimit,
  fromTab,
  setDisplayDiskUsageWarning,
  onOpenCustomPlanDialog,
  onOpenUpgradeDiskDialog,
}: Props) {
  const { name, cluster, namespace } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // HOOK
  const { translate } = useLocales();

  const { highestUserRole, isHighestUserRoleLoading } = useHighestUserRole();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // EVENT FUNCTION
  function handleSwitchPlan() {
    if (!plan || !name) return;

    dispatch(
      setUpgradePlanDetails({
        name: name,
        currentPlan: { name: plan, billing: site.billing.name },
        currentDiskSpace: localDiskLimit,
        fromTab,
      })
    );
    navigate(`/sites/${cluster}/${namespace}/${name}/change-plan`);
  }

  // HELPER FUNCTION
  function displayDiskUsageWarningAction() {
    // Hostnet customers should not see the action button at all
    // ALL users will see the increase disk space button when usage < limit
    // ONLY support / sa / customer owner will see the change plan option when usage >= limit

    if (isHostnetCustomer(user) || isHighestUserRoleLoading) return false;
    if (
      user?.role === UserRoleEnum.customer &&
      localDiskLimit >= planDiskLimit &&
      highestUserRole !== AccessTypeEnum.owner
    )
      return false;

    return true;
  }

  return (
    <div className="gv-activated">
      <Alert
        type="warning"
        title={translate(
          `wpone.sites.details.upgradeDiskWarningBanner.${
            localDiskLimit >= planDiskLimit ? 'reachedPlanLimit' : 'haventReachedPlanLimit'
          }.title`
        )}
        text={translate(
          `wpone.sites.details.upgradeDiskWarningBanner.${
            localDiskLimit >= planDiskLimit ? 'reachedPlanLimit' : 'haventReachedPlanLimit'
          }.description${
            localDiskLimit >= planDiskLimit
              ? ''
              : fromTab === 'overview'
              ? '.overviewTab'
              : '.planTab'
          }`
        )}
        button={
          displayDiskUsageWarningAction() ? (
            <Button
              uiType="neutral"
              text={translate(
                `wpone.sites.details.upgradeDiskWarningBanner.${
                  localDiskLimit >= planDiskLimit ? 'reachedPlanLimit' : 'haventReachedPlanLimit'
                }.action`
              )}
              onClick={
                localDiskLimit >= planDiskLimit
                  ? user?.role === UserRoleEnum.customer &&
                    ((plan === SitePlanEnum.enterprise && localDiskLimit === 80) ||
                      localDiskLimit > 80)
                    ? onOpenCustomPlanDialog
                    : handleSwitchPlan
                  : onOpenUpgradeDiskDialog
              }
            />
          ) : undefined
        }
        handleClose={() => {
          setDisplayDiskUsageWarning(false);
        }}
      />
    </div>
  );
}
